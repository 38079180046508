import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { getSocket, socketConnected, getSymOrder } from './Socket';
import { createChartInfoObject, getHRcolor } from './Chart';
import { getPages, getNavigation } from './App';
import { Avatar, Box, Button, CssBaseline, Dialog, DialogActions, DialogContent, Divider, Drawer, FormControl, IconButton, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Stack, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Circle as CircleIcon,
    Comment as CommentIcon,
    Save as SaveIcon,
    Menu as MenuIcon,
    PowerSettingsNew as PowerSettingsNewIcon,
} from '@mui/icons-material';
import { Scatter } from 'react-chartjs-2'
import { MapContainer, TileLayer, Marker, Polyline, useMap } from 'react-leaflet';


const loggingButtonTimeout = 500;


const defaultData = {
    system: {
        piJuice: Object(),
        info: Object(),
        available: undefined,
    },
    inertia: {
        status: Object(),           // connection info
        sensors: Object(),          // node info
        sync: Object(),             // fill-loss info
        order: [],                  // order of sensors in table
        nodePositions: Object(),    // assigned positions
        gnssSensor: 0,              // gnssSensor used in chart and map
    },
    polar: {
        status: Object(),
        info: [],
    },
};


class System extends Component {
    constructor(props) {
        super(props);

        this.defaultTheme = createTheme({ palette: { mode: 'dark' } });

        // allow to use 'this' to access class members
        this.connectHandler = this.connectHandler.bind(this);
        this.dataHandler = this.dataHandler.bind(this);
        this.roomHandler = this.roomHandler.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.horseHandler = this.horseHandler.bind(this);
        this.measurementHandler = this.measurementHandler.bind(this);

        this.data = JSON.parse(JSON.stringify(defaultData));
        this.chart = createChartInfoObject(true);

        this.firstCoord = true;
        this.loggingTimerID = undefined;
        this.lastCommentCount = 0;

        // gui updates when any of these change
        this.state = {
            isConnected: socketConnected(),
            navigate: undefined,
            drawerOpen: false,
            powerDownConfirm: false,
            stopConfirm: false,
            shutDownConfirm: false,
            lastUpdate: Date.now(),
            rooms: [],
            users: [],
            horses: [],
            trainingTypes: [],
            trainingTypeID: parseInt(localStorage.getItem('trainingTypeID')) || null,
            activeRoom: '',
            polarAddress: '',
            comment: '',
            comments: [],
            commentSaveIconColor: undefined,
            loggingDuration: null,
            loggingType: '', // '', 'pre-hr', 'normal', 'post-hr'
            loggingButtonClickTime: 0,
            resyncResponse: '',
        };

        // select requested system from overview page or last selected system
        this.pendingRoomID = this.props.system;
        if (this.pendingRoomID === undefined)
            this.pendingRoomID = localStorage.getItem('selectedRoomID');
        localStorage.setItem('selectedRoomID', this.pendingRoomID);
        this.props.setSystem(undefined);
    }

    componentDidMount() {
        var socket = getSocket();
        if (socket) {
            socket.on('connect', this.connectHandler);
            socket.on('disconnect', this.connectHandler);
            socket.on('data', this.dataHandler);
            socket.on('room', this.roomHandler);
            socket.on('users', this.userHandler);
            socket.on('horses', this.horseHandler);
            socket.on('measurements', this.measurementHandler);

            this.getUsers();
            this.getHorses();
            this.getTrainingTypes();

            if (socketConnected())
                this.onGetRPis();
        }

        const L = require('leaflet');
        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png')
        });

        this.loggingTimerID = setInterval(() => {
            this.updateLoggingTimer();
        }, 1000);
    }

    componentWillUnmount() {
        var socket = getSocket();
        if (socket) {
            socket.emit('room', 'request', 'leave', this.state.activeRoom);
            socket.off('connect', this.connectHandler);
            socket.off('disconnect', this.connectHandler);
            socket.off('data', this.dataHandler);
            socket.off('room', this.roomHandler);
            socket.off('users', this.userHandler);
            socket.off('horses', this.horseHandler);
            socket.off('measurements', this.measurementHandler);
        }

        clearInterval(this.loggingTimerID);
        this.loggingTimerID = undefined;

        const chart = this.chart.reference.current;
        if (chart)
            chart.destroy();
    }

    connectHandler(reason) {
        var changed = this.state.isConnected !== socketConnected();
        this.setState({ isConnected: socketConnected() });

        if (reason === 'io server disconnect')
            this.getUsers();

        if (changed)
            this.onGetRPis();

        this.joinRoom(this.state.activeRoom);
    }

    getUsers() {
        var socket = getSocket();
        socket.emit('users', 'request', 'get');
    }

    getHorses() {
        var socket = getSocket();
        socket.emit('horses', 'request', 'get');
    }

    getTrainingTypes() {
        var socket = getSocket();
        socket.emit('measurements', 'request', 'getTrainingTypes');
    }

    getMeasurementID(newMeasurement) {
        var measurementID = undefined;

        if (newMeasurement) {
            if (this.data.polar.status.logging)
                measurementID = this.data.polar.status.measurementID;
            else if (this.data.inertia.status.logging)
                measurementID = this.data.inertia.status.measurementID;
            else
                measurementID = Date.now() + '_' + this.props.username;
        } else {
            measurementID = (this.data.polar.status.logStartTime > this.data.inertia.status.logStartTime) ? this.data.polar.status.measurementID : this.data.inertia.status.measurementID;;
        }

        return measurementID;
    }

    updateOrder() {
        this.data.inertia.order = [];
        for (const [sn, item] of Object.entries(this.data.inertia.sensors)) {
            if (!item)
                return;

            this.data.inertia.order.push({
                sn: parseInt(sn),
                isGateway: item.isGateway,
                nodeIDLabel: item.nodeIDLabel,
            });
        }

        function compareFn(a, b) {
            var aOK = a.isGateway !== undefined || a.nodeIDLabel !== undefined;
            var bOK = b.isGateway !== undefined || b.nodeIDLabel !== undefined

            // both invalid, order by sn
            if (!aOK && !bOK)
                return a.sn - b.sn;

            // valid before invalid
            if (aOK !== bOK)
                return aOK ? -1 : 1;

            // gateway before nodes
            if (a.isGateway !== b.isGateway)
                return a.isGateway ? -1 : 1;

            // sort by nodeID
            try {
                var aElements = a.nodeIDLabel.trim().split(' ');
                var bElements = b.nodeIDLabel.trim().split(' ');
                var aNodeID = aElements.length > 0 ? parseInt(aElements[0]) : 0;
                var bNodeID = bElements.length > 0 ? parseInt(bElements[0]) : 0;
                if (aNodeID !== bNodeID)
                    return aNodeID - bNodeID;

                // sort by hwID
                var aHwID = aElements.length > 1 ? parseInt(aElements[1].substring(1, aElements[1].length - 1), 16) : 0;
                var bHwID = bElements.length > 1 ? parseInt(bElements[1].substring(1, bElements[1].length - 1), 16) : 0;
                return aHwID - bHwID;
            } catch (e) {
                // some problem with parseInt
            }
            return a.sn - b.sn;
        }

        this.data.inertia.order.sort(compareFn);
    }

    useForSymmetry(sn) {
        var result = false;
        var serialNr = parseInt(sn);

        for (var pos of getSymOrder()) {
            var posSn = this.data.inertia.nodePositions[pos]?.serialNr;
            if (posSn === serialNr)
                result = true;
            var hasAvailableSn = posSn !== 0 && this.data.inertia.sensors[posSn];
            if (result || hasAvailableSn)
                break;
        }

        return result;
    }

    getComments(room, data, force = false) {
        var getInitialComments = this.data.inertia.status.logging !== true && this.data.polar.status.logging !== true && data.logging === true;
        if (force || getInitialComments) {
            // request (empty) comments of the (new) measurement
            var socket = getSocket();
            socket.emit('measurements', 'request', 'getComments', {
                measurementID: data.measurementID,
                systemID: this.state.rooms.find((roomInfo) => roomInfo.room === room)?.userID,
            });
        }
    }

    dataHandler(room, channel, subChannel, data) {
        if (room !== this.state.activeRoom)
            return;

        if (channel === 'inertia') {
            var sensors = this.data.inertia.sensors;

            if (subChannel === 'status') {
                this.getComments(room, data);

                this.data.inertia.status = data;
                this.updateLoggingTimer();

                if (!this.data.inertia.status.connected) {
                    this.data.inertia.sensors = Object();
                    this.data.inertia.sync = Object();
                    this.updateOrder();
                }
                if (this.data.inertia.status.logging) {
                    this.data.inertia.sync = Object();
                    this.setState({ trainingTypeID: this.data.inertia.status.trainingTypeID });
                }
            }
            else if (subChannel === 'resync') {
                this.setState({ resyncResponse: data });
                setTimeout(() => {
                    this.setState({ resyncResponse: undefined });
                }, 3000);
            }
            else if (subChannel === 'nodeData' || subChannel === 'nodeInfo') {
                var updateOrder = subChannel === 'nodeInfo';
                for (const [key, value] of Object.entries(data)) {
                    if (!sensors[key]) {
                        sensors[key] = Object();
                        updateOrder = true;
                    }
                    Object.assign(sensors[key], value);

                    if (subChannel === 'nodeData' && this.useForSymmetry(key)) {
                        if (!value.symreg || value.symreg.sym === null || isNaN(value.symreg.sym))
                            this.addChartData(0, Date.now(), NaN);
                        else
                            this.addChartData(0, Date.now(), value.symreg.sym);
                    }
                }
                if (updateOrder)
                    this.updateOrder();
            }
            else if (subChannel === 'nodeRemoved') {
                delete this.data.inertia.sensors[data];
                if (data === this.data.inertia.gnssSensor) {
                    this.data.inertia.gnssSensor = 0;
                }
                this.updateOrder();
            }
            else if (subChannel === 'progress') {
                var sync = this.data.inertia.sync;
                if (data.serialNr !== undefined) {
                    if (!sync[data.serialNr])
                        sync[data.serialNr] = Object();
                    sync[data.serialNr].percentage = data.percentage;
                } else if (data.success !== undefined) {
                    for (const value of Object.values(sync))
                        value.success = data.success;
                    if (data.success === null)
                        this.data.inertia.sync = Object();
                }
            }
            else if (subChannel === 'gnss') {
                for (const [sn, gnssData] of Object.entries(data)) {
                    if (gnssData && gnssData.length > 0 && sensors[sn]) {
                        if (!sensors[sn].gnss)
                            sensors[sn].gnss = [];
                        sensors[sn].gnss.push(...gnssData);

                        // at least 20 gnss points, max 20 seconds
                        var gnssCount = sensors[sn].gnss.length;
                        var lastGnssTime = sensors[sn].gnss[gnssCount - 1].utc;
                        var gnssRemoveIndex = 0;
                        for (gnssRemoveIndex = 0; gnssRemoveIndex < gnssCount; ++gnssRemoveIndex) {
                            var timeDiff = (lastGnssTime - sensors[sn].gnss[gnssRemoveIndex].utc);
                            if (timeDiff < 20000)
                                break;
                        }
                        gnssRemoveIndex = Math.max(0, Math.min(gnssRemoveIndex, gnssCount - 20));
                        sensors[sn].gnss = sensors[sn].gnss.slice(gnssRemoveIndex);
                    }
                }

                // determine sensor with highest number of SVs
                var newGnssSensor = 0;
                var newGnssSats = 0;
                for (const [sn, item] of Object.entries(sensors)) {
                    if (item && item.gnss && item.gnss.length > 0) {
                        var numberSvsInFix = item.gnss[item.gnss.length - 1].numberSvsInFix;
                        if (newGnssSensor === 0 || numberSvsInFix > newGnssSats) {
                            newGnssSensor = sn;
                            newGnssSats = numberSvsInFix;
                        }
                    }
                }
                this.data.inertia.gnssSensor = newGnssSensor;

                // only plot the last gnss sample received
                var gnssData = this.data.inertia.sensors[this.data.inertia.gnssSensor]?.gnss;
                if (gnssData && gnssData.length > 0) {
                    var gnssSample = gnssData[gnssData.length - 1];
                    var timestamp = gnssSample.valid ? Date.parse(gnssSample.utc) : Date.now();
                    this.addChartData(3, timestamp, gnssSample.numberSvsInFix);
                    if (gnssSample.valid) {
                        this.addChartData(2, timestamp, gnssSample.speedOverGround * 3.6);
                    }
                }
            }
            else if (subChannel === 'nodePositions') {
                Object.assign(this.data.inertia.nodePositions, data);
            }
            else {
                console.log('unhandled inertia ' + subChannel + ' data=' + JSON.stringify(data));
            }

            this.setState({ lastUpdate: Date.now() });
        }
        else if (channel === 'polar') {

            if (subChannel === 'status') {
                this.getComments(room, data);

                this.data.polar.status = data;
                this.updateLoggingTimer();

                if (this.data.polar.status.logging) {
                    this.setState({ trainingTypeID: this.data.polar.status.trainingTypeID });
                }
            }
            else if (subChannel === 'info') {
                this.data.polar.info = data;
                for (var device of this.data.polar?.info) {
                    if (device.selected) {
                        this.setState({ polarAddress: device.address });
                    }
                }
            }
            else if (subChannel === 'hr') {
                this.addChartData(1, data.date, data.heartRate);
            }
            else {
                console.log('unhandled polar ' + subChannel + ' data=' + JSON.stringify(data));
            }

            this.setState({ lastUpdate: Date.now() });
        } else if (channel === 'system') {
            if (subChannel === 'online') {
                this.data.system.available = true;
                this.data.system.info = data;
            }
            else if (subChannel === 'piJuice') {
                this.data.system.piJuice = data;
            }
            else if (subChannel === 'network') {
                this.data.system.network = data;
            }
            else {
                console.log('unhandled system ' + subChannel + ' data=' + JSON.stringify(data));
            }
            this.setState({ lastUpdate: Date.now() });
        }
        else {
            console.log('unhandled ' + channel + ' ' + subChannel);
        }
    }

    roomHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'list') {
                this.setState({ rooms: data });

                if (this.pendingRoomID) {
                    // select pending
                    this.setState({ activeRoom: this.pendingRoomID });
                    this.onSelectRPi(this.pendingRoomID);
                    this.pendingRoomID = undefined;
                }
                else if (data.find((room) => room.room === this.state.activeRoom) === undefined) {
                    // deselect removed room
                    this.setState({ activeRoom: '' });
                    this.onSelectRPi('');
                }
            }
            else if (subChannel === 'join') {
                this.data.system.available = true;
            }
            else if (subChannel === 'leave') {
                this.data.system.available = false;
            }
            else {
                console.log('unhandled ' + channel + ' ' + subChannel);
            }
            this.setState({ lastUpdate: Date.now() });
        }
        else
            console.log('unhandled ' + channel + ' ' + subChannel);
    }

    userHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ users: data });
            }
        }
    }

    horseHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ horses: data });
            }
            else {
                this.getHorses();
            }
        }
    }

    measurementHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'addComment') {
                if (this.state.commentSaveIconColor === 'warning') {
                    this.setState({
                        comment: '',
                        commentSaveIconColor: data ? 'success' : 'error',
                    });
                    setTimeout(() => {
                        this.setState({ commentSaveIconColor: undefined });
                    }, 3000);
                }
                this.getComments(this.state.activeRoom, { measurementID: this.getMeasurementID(false) }, true);
            }
            else if (subChannel === 'getComments') {
                this.setState({
                    comments: data,
                });
            }
            else if (subChannel === 'getTrainingTypes') {
                this.setState({ trainingTypes: data });
            }
        }
    }

    showSyncColumn() {
        return Object.keys(this.data.inertia.sync).length > 0;
    }

    renderTableRows() {
        const getFlashCheckbox = (item) => {
            if (item.isGateway || item.isFlashLogging === undefined)
                return;

            return <input className='form-check-input centered-checkbox' type='checkbox' disabled checked={item.isFlashLogging === 1} />;
        };

        const getProgressBarTD = (sn) => {
            if (!this.showSyncColumn())
                return;

            var itemSync = this.data.inertia.sync[sn];
            var percentage = itemSync?.percentage;
            if (percentage === undefined) {
                return (
                    <td className='text-center align-middle'></td>
                )
            }

            var syncColor; // default (dark blue)
            if (itemSync.success === true)
                syncColor = 'bg-success';
            else if (itemSync.success === false)
                syncColor = 'bg-danger';
            var syncClass = 'progress-bar pb-sync ' + syncColor;

            return (
                <td className='text-center align-middle'>
                    <div className='my-progress progress progress-sync'>
                        <div className={syncClass} role='progressbar' aria-valuenow={percentage} aria-valuemin='0' aria-valuemax='100' style={{ width: percentage + '%' }}>{percentage.toFixed(0) + '%'}</div>
                    </div>
                </td>
            );
        }

        const getPositionSelectItems = () => {
            const renderedItems = [];
            renderedItems.push(
                <MenuItem key={'empty'} value={''}>{'\u00a0'}</MenuItem>
            );
            for (const [pos, item] of Object.entries(this.data.inertia.nodePositions)) {
                renderedItems.push(
                    <MenuItem key={pos} value={pos}>{item.label}</MenuItem>
                );
            }
            return renderedItems;
        }

        const onSelectSensorPosition = (event) => {
            var serialNr = parseInt(event?.target?.name || 0);
            var position = event?.target?.value;
            if (!serialNr || position === undefined)
                return;

            var currentPos = undefined;
            for (const [pos, item] of Object.entries(this.data.inertia.nodePositions)) {
                // get current position of sensor
                if (item.serialNr === serialNr)
                    currentPos = pos;
            }

            // clear current position
            if (currentPos)
                this.data.inertia.nodePositions[currentPos].serialNr = 0;

            // assign new position
            if (position)
                this.data.inertia.nodePositions[position].serialNr = serialNr;

            this.setState({ lastUpdate: Date.now() });


            var socket = getSocket();
            socket.emit('control', this.state.activeRoom, 'inertia', 'setPositions', this.data.inertia.nodePositions);
        }

        const getPositionSelect = (sn, item) => {
            if (!this.data.inertia.nodePositions)
                return;

            if (!item || item.isGateway)
                return;

            var selectedPos = '';
            for (const [pos, item] of Object.entries(this.data.inertia.nodePositions)) {
                if (item.serialNr === sn)
                    selectedPos = pos;
            }

            var disablePositions = this.data.inertia.status?.logging === true || this.data.inertia.status?.syncing === true;

            return (
                <FormControl sx={{ minWidth: 120 }} size='small'>
                    <Select
                        name={'' + sn}
                        value={selectedPos}
                        displayEmpty
                        onChange={onSelectSensorPosition}
                        disabled={disablePositions}
                    >
                        {getPositionSelectItems()}
                    </Select>
                </FormControl>
            );
        }

        const renderedItems = [];

        for (var info of this.data.inertia.order) {
            var sn = info.sn;
            var item = this.data.inertia.sensors[sn];
            if (!item)
                continue;
            var label = item.nodeIDLabel ? ((item.device ? item.device.devName : (item.isGateway ? 'Gateway' : 'Node')) + ' ' + item.nodeIDLabel) : sn;
            if (window.innerWidth < 600)
                label = (item.isGateway ? 'gw ' : '') + item.nodeIDLabel;
            var rssi = item.rssi !== undefined ? ('icon__signal-strength signal-' + item.rssi) : '';
            var loss = (!isNaN(item.loss) && item.loss != null) ? (Math.round(item.loss) + '%') : '';

            var validBat = !isNaN(item.bat) && item.bat != null;
            var bat = validBat ? (Math.round(item.bat) + '%' + (item.charging ? ' \u26A1' : '')) : '';
            var batColor = (!validBat || item.bat >= 25) ? undefined : (item.bat >= 10 ? 'orange' : 'red');

            renderedItems.push(
                <tr key={sn} className='inertia-table-row'>
                    <td className='align-middle'>{label}</td>
                    <td className='text-center align-middle'>{getPositionSelect(sn, item)}</td>
                    <td className='text-center align-middle'>
                        <i className={rssi}><span className='bar-1'></span><span className='bar-2'></span><span className='bar-3'></span><span className='bar-4'></span></i>
                    </td>
                    <td className='text-center align-middle'>{loss}</td>
                    <td className='text-center align-middle'><span style={{ color: batColor }}>{bat}</span></td>
                    <td className='text-center align-middle'>{getFlashCheckbox(item)}</td>
                    {getProgressBarTD(sn)}
                </tr>
            );
        }

        return renderedItems;
    }

    getConnectedStyle(available) {
        return {
            color: available ? undefined : 'red',
            fontWeight: available ? undefined : 'bold',
        };
    }

    getServerStatus() {
        var connected = this.state.isConnected === true;
        if (connected)
            return;

        return (
            <div>{'Server: '}<span style={this.getConnectedStyle(connected)}>{(connected ? 'online' : 'offline')}</span></div>
        )
    }

    onGetRPis() {
        var socket = getSocket();
        socket.emit('room', 'request', 'list');
    }

    joinRoom(room) {
        if (!room)
            return;

        var socket = getSocket();
        if (!socket)
            return;

        socket.emit('room', 'request', 'join', room);
        socket.emit('control', room, 'inertia', 'getInfo');
        socket.emit('control', room, 'polar', 'getInfo');
        socket.emit('control', room, 'system', 'getInfo');
    }

    onSelectRPi = (event) => {
        var socket = getSocket();
        socket.emit('room', 'request', 'leave', this.state.activeRoom);

        this.data = JSON.parse(JSON.stringify(defaultData));
        this.clearChart();

        var room = event?.target ? event.target.value : event;
        this.setState({
            activeRoom: room,
            comment: '',
            polarAddress: '',
            loggingDuration: null,
            loggingType: '',
        });
        this.joinRoom(room);
        localStorage.setItem('selectedRoomID', room);
    }

    updateLoggingTimer() {
        var logStartTime = null;
        var type = '';

        var hrStatus = this.data.polar.status;
        var inStatus = this.data.inertia.status;

        if (inStatus.logging) {
            // normal measurement
            logStartTime = inStatus.logStartTime;
            type = 'normal';
        } else if (hrStatus.logging) {
            // pre/post heart rate
            var post = hrStatus.logStartTime < inStatus.logEndTime;
            if (post) {
                logStartTime = inStatus.logEndTime;
                type = 'post-hr';
            } else {
                logStartTime = hrStatus.logStartTime;
                type = 'pre-hr';
            }
        }

        var duration = null;
        if (logStartTime) {
            var time = Math.round((Date.now() - logStartTime) / 1000);
            if (time < 0)
                time = 0; // don't show negative duration when there is a small offset between RPi and viewer time

            var hours = Math.floor(time / 60 / 60);
            var minutes = Math.floor(time / 60 % 60);
            var seconds = Math.floor(time % 60);

            duration = '';
            if (hours > 0)
                duration += hours + ':';
            duration += (minutes < 10 ? '0' : '') + minutes + ':';
            duration += (seconds < 10 ? '0' : '') + seconds;
        }

        if (duration !== this.state.loggingDuration || type !== this.state.loggingType) {
            this.setState({
                loggingDuration: duration,
                loggingType: type,
            });
        }
    }

    renderInertia() {
        if (!this.state.activeRoom)
            return;

        const onPowerDown = () => {
            this.setState({ powerDownConfirm: true });
        }

        const getInertiaConnectionStatus = () => {
            var result = 'Connection status: ';
            var status = this.data.inertia.status;
            if (!status)
                result += 'n/a';
            else if (status.connectFeedback)
                result += status.connectFeedback;
            else
                result += (status.connected === true ? 'Connected' : 'Not connected');
            return result;
        }

        const getProgressBarTH = () => {
            if (!this.showSyncColumn())
                return;

            return (
                <th className='text-center align-middle'>Sync</th>
            )
        }

        const getPowerDownButton = () => {
            if (this.data.inertia.status?.connected !== true)
                return;

            var disablePowerDown = this.data.inertia.status?.logging === true || this.data.inertia.status?.syncing === true || this.data.inertia.status?.manualFileSync === true;

            return (
                <Button
                    sx={{
                        display: 'block',
                        marginRight: 0,
                        marginLeft: 'auto',
                    }}
                    variant='contained'
                    onClick={onPowerDown}
                    disabled={disablePowerDown}
                >
                    Power Down Sensors
                </Button>
            )
        }

        return (
            <Box className='sub-box'>
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                    }}
                >
                    <Box>{getInertiaConnectionStatus()}</Box>
                    {getPowerDownButton()}
                </Stack >

                <div>
                    <table className='table' style={{ width: '100%', marginTop: '0.5em' }}>
                        <thead>
                            <tr>
                                <th className='text-left align-middle'>Name</th>
                                <th className='text-center align-middle'>Position</th>
                                <th className='text-center align-middle'>Signal</th>
                                <th className='text-center align-middle'>Loss</th>
                                <th className='text-center align-middle'>Battery</th>
                                <th className='text-center align-middle'>Flash</th>
                                {getProgressBarTH()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRows()}
                        </tbody>
                    </table>
                </div>
            </Box>
        );
    }

    addChartData(dsIndex, x, y) {
        const chart = this.chart.reference.current;
        var ds = chart?.data?.datasets;
        if (!ds || dsIndex >= ds.length)
            return;

        var dataset = ds[dsIndex];
        dataset.data.push({
            x: x,
            y: (isNaN(y) ? NaN : y),
        });

        if (x > 0 && (this.chart.maxTime === null || this.chart.maxTime < x))
            this.chart.maxTime = x;

        Object.values(chart.options.scales).forEach(axis => {
            if (axis.id === 'x') {
                axis.min = this.chart.maxTime - (this.chart.chartWidth * 1000);
                axis.max = this.chart.maxTime;
            }
        });

        if (dataset.data.length > (2 * this.chart.chartWidth)) {
            var removeBefore = this.chart.maxTime - (this.chart.chartWidth * 1000);
            dataset.data = dataset.data.filter((item) => item.x >= removeBefore);
        }

        chart.update('none');
    }

    clearChart() {
        const chart = this.chart.reference.current;
        var ds = chart?.data?.datasets;
        if (!chart || !ds)
            return;

        for (var dsIndex = 0; dsIndex < ds.length; ++dsIndex) {
            var dataset = ds[dsIndex];
            dataset.data = [];
        }
        this.chart.maxTime = null;

        chart.update('none');
    }

    renderCharts() {
        if (!this.state.activeRoom)
            return;

        const getPolarDevices = () => {
            const renderedItems = [];
            for (var device of this.data.polar.info) {
                renderedItems.push(
                    <MenuItem key={device.address} value={device.address}>{device.address ? (device.name || device.address) : '\u00a0'}</MenuItem>
                );
            }
            return renderedItems;
        }

        const renderPolar = (selected) => {
            return this.data.polar.info.find((device) => device.address === selected)?.name || '';
        };

        const getPolarConnectionInfo = () => {
            var status = this.data.polar.status;

            if (!status)
                return 'n/a';
            else if (status?.scanning)
                return 'Scanning...';
            else if (status?.connected)
                return 'Connected';
            else
                return 'Disconnected';
        };

        const onSelectPolar = (event) => {
            var address = event?.target ? event.target.value : event;
            this.setState({
                polarAddress: address,
            });

            var socket = getSocket();
            socket.emit('control', this.state.activeRoom, 'polar', 'select', address);
        };

        return (
            <Box className='sub-box'>
                <Box sx={{ alignItems: 'center', display: 'flex', marginLeft: '20px', gap: '20px' }}>
                    <FormControl fullWidth sx={{ width: 250 }}>
                        <InputLabel id='polar-label'>Heart Rate Sensor</InputLabel>
                        <Select
                            labelId='polar-label'
                            id='polar-label'
                            value={this.state.polarAddress}
                            label='Heart Rate Sensor'
                            onChange={onSelectPolar}
                            renderValue={renderPolar}
                            disabled={!this.data.system.available || this.data.polar.info.length <= 0 || (this.data.polar.status.logging && !!this.state.polarAddress )}
                        >
                            {getPolarDevices()}
                        </Select>
                    </FormControl>
                    <span>{getPolarConnectionInfo()}</span>
                </Box>

                <Box className='chart-container'>
                    <Scatter ref={this.chart.reference} options={this.chart.options} data={this.chart.data} />
                </Box>
            </Box>
        );
    }

    renderRPis() {
        const getRpiInfo = () => {
            if (!this.state.activeRoom)
                return '';

            const getSystemInfo = () => {
                var result = '';
                if (this.data.system.info.rpi)
                    result += this.data.system.info.rpi.model;
                else if (this.data.system.info.hostname)
                    result += this.data.system.info.hostname;
                else
                    result += 'n/a';
                return result;
            }

            const getAvailableInfo = () => {
                var available = false;
                if (this.data.system.available !== undefined)
                    available = this.data.system.available === true;
                else {
                    var room = this.state.rooms.find((room) => room.room === this.state.activeRoom);
                    available = room && room.available;
                }

                return (
                    <div>{'Available: '}<span style={this.getConnectedStyle(available)}>{(available ? 'yes' : 'no')}</span></div>
                )
            }

            const getPiJuiceInfo = () => {
                var result = '';

                var pj = this.data.system?.piJuice;
                if (pj?.error && pj.error !== 'NO_ERROR') {
                    result += pj.error;
                } else if (pj?.battery && pj?.data) {
                    result += pj.battery.chargeLevel + '%';
                    if (pj.battery.capacity)
                        result += ' (' + (pj.battery.capacity / 1000).toFixed(0) + 'Ah)';
                    if (pj.data.battery !== 'NORMAL') {
                        if (pj.data.battery === 'CHARGING_FROM_IN')
                            result += ' \u26A1';
                        else
                            result += ' - ' + pj.data.battery;
                    }
                }

                var chargeLevel = pj?.battery?.chargeLevel;
                var batColor = (chargeLevel === undefined || chargeLevel >= 25) ? undefined : (chargeLevel >= 10 ? 'orange' : 'red');
                if (pj?.error !== 'NO_ERROR')
                    batColor = 'red';

                return (
                    <div>{'Battery: '}<span style={{ color: batColor }}>{result}</span></div>
                )
            }

            const getNetworkInfo = () => {
                var info = '';

                var networkInfo = this.data.system.network;
                if (!networkInfo)
                    info = '';
                else if (networkInfo.type === 0)
                    info = 'N/A'
                else if (networkInfo.type === 1)
                    info = 'wifi';
                else if (networkInfo.type === 2)
                    info = '4g';
                else if (networkInfo.type === 3)
                    info = '4g + hotspot';
                else
                    info = 'unknown';

                return (
                    <div>{'Network: '}<span>{info}</span></div>
                )
            }

            const getShutDown = () => {
                const onPowerDown = () => {
                    this.setState({ shutDownConfirm: true });
                };

                var enablePowerDown = this.data.system.available && this.data.inertia.status.logging === false && this.data.inertia.status.syncing === false && this.data.polar.status.logging === false;

                return (
                    <div>{'Shut down: '}
                        <IconButton
                            onClick={onPowerDown}
                            disabled={!enablePowerDown}
                            sx={{ padding: 0 }}
                        >
                            <PowerSettingsNewIcon />
                        </IconButton>
                    </div>
                );
            }

            return (
                <Box>
                    <Box>{getSystemInfo()}</Box>
                    <Box>{getAvailableInfo()}</Box>
                    <Box>{getPiJuiceInfo()}</Box>
                    <Box>{getNetworkInfo()}</Box>
                    <Box>{getShutDown()}</Box>
                </Box>
            )
        }

        const getLoggingButtons = () => {
            if (!this.state.activeRoom)
                return '';

            const isInertiaLoggingDisabled = () => {
                if (!this.data.system.available)
                    return true;
                var status = this.data.inertia.status;
                if (!status || !status.connected)
                    return true;

                if (this.state.loggingType === 'post-hr' && !status.syncing)
                    return true;

                if (status.manualFileSync)
                    return true;

                return false;
            }

            const onToggleInertiaLog = () => {
                if (isInertiaLoggingDisabled())
                    return;

                if (Date.now() - this.state.loggingButtonClickTime < loggingButtonTimeout)
                    return;
                this.setState({ loggingButtonClickTime: Date.now() });
                setTimeout(() => {
                    this.setState({ loggingButtonClickTime: 0 });
                }, loggingButtonTimeout);

                var status = this.data.inertia.status;
                var start = status.logging === false && status.syncing === false;

                if (start) {
                    var measurementID = this.getMeasurementID(true);
                    var trainingTypeID = this.state.trainingTypeID || null;
                    var socket = getSocket();
                    socket.emit('control', this.state.activeRoom, 'inertia', 'logging', { start, measurementID, trainingTypeID });
                    socket.emit('control', this.state.activeRoom, 'polar', 'logging', { start, measurementID, trainingTypeID, startWithoutSensor: true });
                } else {
                    this.setState({ stopConfirm: true });
                }
            }

            const getInertiaLoggingButtonLabel = () => {
                if (this.data.inertia.status.manualFileSync)
                    return 'Start Measurement';

                return this.data.inertia.status?.logging === true ? 'Stop Measurement' : (this.data.inertia.status?.syncing === true ? 'Stop Sync' : 'Start Measurement');
            }

            const isPolarLoggingDisabled = () => {
                if (!this.data.system.available)
                    return true;

                if (this.state.loggingType === 'normal')
                    return true;

                if (this.data.inertia.status.manualFileSync)
                    return true;

                var status = this.data.polar.status;
                if (!status || ((status.scanning || !status.connected) && !status.logging))
                    return true;

                return false;
            }

            const onTogglePolarLog = () => {
                if (isPolarLoggingDisabled())
                    return;

                if (Date.now() - this.state.loggingButtonClickTime < loggingButtonTimeout)
                    return;
                this.setState({ loggingButtonClickTime: Date.now() });
                setTimeout(() => {
                    this.setState({ loggingButtonClickTime: 0 });
                }, loggingButtonTimeout);

                var start = this.data.polar.status.logging === false;
                var measurementID = start ? this.getMeasurementID(true) : undefined;
                var trainingTypeID = start ? this.state.trainingTypeID : undefined;

                var socket = getSocket();
                socket.emit('control', this.state.activeRoom, 'polar', 'logging', { start, measurementID, trainingTypeID });
            }

            const getPolarLoggingButtonLabel = () => {
                if (this.data.inertia.status.manualFileSync)
                    return 'Start heart rate';

                return this.data.polar.status?.logging === true ? 'Stop heart rate' : 'Start heart rate';
            }

            const getDurationColor = () => {
                var color = undefined;
                if (this.state.loggingType === 'pre-hr' || this.state.loggingType === 'post-hr')
                    color = getHRcolor();
                return color;
            }

            const isInertiaResyncDisabled = () => {
                if (!this.data.system.available)
                    return true;

                var inertiaStatus = this.data.inertia.status;
                var polarStatus = this.data.polar.status;

                if (!inertiaStatus || !inertiaStatus.connected || !polarStatus)
                    return true;

                if (inertiaStatus.logging === true || inertiaStatus.syncing === true || polarStatus.logging === true)
                    return true;

                return false;
            }

            const getInertiaResyncLabel = () => {
                if (this.state.resyncResponse)
                    return this.state.resyncResponse;
                else if (this.data.inertia.status.manualFileSync)
                    return 'Stop Re-sync';
                else
                    return 'Check Re-sync';
            }

            const onToggleResync = () => {
                var socket = getSocket();
                var enable = !this.data.inertia.status.manualFileSync;
                socket.emit('control', this.state.activeRoom, 'inertia', 'sync', { enable });
            }

            const getDurationOrResync = () => {
                if (this.state.loggingDuration !== null) {
                    return (
                        <Box sx={{ alignSelf: 'center', color: getDurationColor(), fontWeight: 'bold', fontSize: '1.5rem' }}>
                            {this.state.loggingDuration}
                        </Box>
                    );
                } else {
                    return (
                        <Button
                            sx={{ width: '100%', marginTop: '1rem' }}
                            variant='outlined'
                            onClick={onToggleResync}
                            disabled={isInertiaResyncDisabled()}
                        >
                            {getInertiaResyncLabel()}
                        </Button>
                    );
                }
            }

            return (
                <Box className='flex-col'>
                    <Button
                        sx={{ width: '100%' }}
                        variant='contained'
                        onClick={onToggleInertiaLog}
                        disabled={isInertiaLoggingDisabled()}
                    >
                        {getInertiaLoggingButtonLabel()}
                    </Button>
                    <Button
                        sx={{ width: '100%', marginTop: '1rem' }}
                        variant='outlined'
                        onClick={onTogglePolarLog}
                        disabled={isPolarLoggingDisabled()}
                    >
                        {getPolarLoggingButtonLabel()}
                    </Button>
                    {getDurationOrResync()}
                </Box >
            )
        }

        const renderComments = () => {
            if (!this.state.activeRoom)
                return '';

            var elements = [];
            var listItemIndex = 0;

            for (const comment of this.state.comments) {
                if (elements.length > 0)
                    elements.push(
                        <Divider
                            key={listItemIndex++}
                            variant='inset'
                            component='li'
                        />
                    );

                elements.push(
                    <ListItem key={listItemIndex++} >
                        <ListItemAvatar>
                            <Avatar>
                                <CommentIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            sx={{ whiteSpace: 'pre-line' }}
                            primary={
                                <Stack
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                    }}
                                >
                                    <Box color='text.primary'>
                                        {this.state.users.find((item) => item.userID === comment.userID)?.username || 'unknown'}
                                    </Box>
                                    <Box sx={{ marginLeft: 'auto' }}>
                                        {new Date(comment.date).toLocaleTimeString()}
                                    </Box>
                                </Stack>
                            }
                            secondary={comment.comment}
                        />
                    </ListItem>
                );
            }

            if (this.lastCommentCount !== this.state.comments.length) {
                setTimeout(() => {
                    const commentBox = document.getElementById('measurement-notes');
                    commentBox.scrollIntoView({ behavior: 'smooth' });
                }, 100);
                this.lastCommentCount = this.state.comments.length;
            }

            const onSaveMeasurementNotes = () => {
                if (!this.state.comment)
                    return;

                var socket = getSocket();
                socket.emit('measurements', 'request', 'addComment', {
                    measurementID: this.getMeasurementID(false),
                    systemID: this.state.rooms.find((roomInfo) => roomInfo.room === this.state.activeRoom)?.userID,
                    comment: this.state.comment,
                });
                this.setState({
                    commentSaveIconColor: 'warning',
                });
            }

            var inertiaStatus = this.data.inertia.status;
            var polarStatus = this.data.polar.status;
            var enableComments = inertiaStatus.logging === true || inertiaStatus.syncing === true || polarStatus.logging === true;
            enableComments ||= inertiaStatus.measurementID !== undefined || polarStatus.measurementID !== undefined;

            elements.push(
                <ListItem key={listItemIndex++} >
                    <TextField
                        sx={{ width: '100%' }}
                        id='measurement-notes'
                        label='Notes (for active or latest measurement)'
                        multiline
                        disabled={!enableComments}
                        rows={3}
                        value={this.state.comment}
                        onChange={e => this.setState({ comment: e.target.value })}
                        InputProps={{
                            endAdornment:
                                <IconButton
                                    disabled={!enableComments}
                                    sx={{ alignSelf: 'end', }}
                                    onClick={onSaveMeasurementNotes}
                                    color={this.state.commentSaveIconColor}
                                >
                                    <SaveIcon />
                                </IconButton>
                        }}
                    />
                </ListItem>
            );

            return (
                <Box sx={{ width: '400px', height: '200px', overflow: 'auto' }}>
                    <List sx={{ width: '100%', padding: 0, }} >
                        {elements}
                    </List>
                </Box>
            );
        }

        const getSystemSelect = () => {
            const renderRPiLabel = (selected) => {
                var userID = this.state.rooms.find((room) => room.room === selected)?.userID;
                var systemName = this.state.users.find((item) => item.userID === userID)?.username || '';
                return systemName;
            }

            const getRPis = () => {
                const renderedItems = [];
                renderedItems.push(
                    <MenuItem key={'empty'} value={''}>
                        <CircleIcon sx={{ marginRight: '8px', fontSize: '1rem', color: 'transparent' }} />
                        <em>none</em>
                    </MenuItem>
                );
                for (const roomInfo of this.state.rooms) {
                    var systemName = this.state.users.find((item) => item.userID === roomInfo.userID)?.username || '';
                    var horseName = this.state.horses.find((item) => item.systemID === roomInfo.userID)?.name || '';
                    var label = systemName;
                    if (horseName)
                        label += ' - ' + horseName;

                    renderedItems.push(
                        <MenuItem key={roomInfo.room} value={roomInfo.room}>
                            <CircleIcon sx={{ marginRight: '8px', fontSize: '1rem', color: roomInfo.available ? '#008000' : '#dc3545' }} />
                            {label}
                        </MenuItem>
                    );
                }
                renderedItems.sort((a, b) => {
                    var name1 = (a.key === 'empty' || a.props.children.length < 2) ? '' : a.props.children[1];
                    var name2 = (b.key === 'empty' || a.props.children.length < 2) ? '' : b.props.children[1];
                    return name1.localeCompare(name2);
                });
                return renderedItems;
            }

            return (
                <FormControl fullWidth sx={{ width: 250 }}>
                    <InputLabel id='system-label'>Select system</InputLabel>
                    <Select
                        labelId='system-label'
                        id='system-label'
                        value={this.state.activeRoom}
                        label='Select system'
                        onOpen={this.onGetRPis}
                        onChange={this.onSelectRPi}
                        renderValue={renderRPiLabel}
                    >
                        {getRPis()}
                    </Select>
                </FormControl>
            );
        }

        const getHorseSelect = () => {
            if (!this.state.activeRoom)
                return '';

            const getHorses = () => {
                const renderedItems = [];
                renderedItems.push(
                    <MenuItem key={'empty'} value={''}><em>none</em></MenuItem>
                );
                for (const horse of this.state.horses) {
                    renderedItems.push(
                        <MenuItem key={horse.horseID} value={horse.horseID}>{horse.name}</MenuItem>
                    );
                }
                renderedItems.sort((a, b) => {
                    var name1 = a.key === 'empty' ? '' : a.props.children;
                    var name2 = b.key === 'empty' ? '' : b.props.children;
                    return name1.localeCompare(name2);
                });
                return renderedItems;
            }

            const onSelectHorse = (event) => {
                var horseID = event?.target?.value || null;
                var systemID = this.state.rooms.find((room) => room.room === this.state.activeRoom)?.userID;

                var socket = getSocket();
                socket.emit('horses', 'request', 'set', {
                    horseID,
                    systemID,
                });
            }

            var measurementActive = this.data.inertia.status.logging === true || this.data.inertia.status.syncing === true || this.data.polar.status.logging === true || this.data.inertia.status.manualFileSync === true;

            var roomInfo = this.state.rooms.find((room) => room.room === this.state.activeRoom);
            var selectedHorse = this.state.horses.find((item) => item.systemID === roomInfo.userID)?.horseID || '';

            return (
                <FormControl fullWidth sx={{ width: 250 }}>
                    <InputLabel id='horse-label'>Horse name</InputLabel>
                    <Select
                        labelId='horse-label'
                        id='horse-label'
                        disabled={measurementActive}
                        value={selectedHorse}
                        label='Horse name'
                        onChange={onSelectHorse}
                    >
                        {getHorses()}
                    </Select>
                </FormControl>
            );
        }

        const getTrainingSelect = () => {
            if (!this.state.activeRoom)
                return '';

            const getTrainingTypes = () => {
                const renderedItems = [];
                renderedItems.push(
                    <MenuItem key={'empty'} value={''}><em>none</em></MenuItem>
                );
                for (const trainingType of this.state.trainingTypes) {
                    renderedItems.push(
                        <MenuItem key={trainingType.trainingTypeID} value={trainingType.trainingTypeID}>{trainingType.name}</MenuItem>
                    );
                }
                renderedItems.sort((a, b) => {
                    var name1 = a.key === 'empty' ? '' : a.props.children;
                    var name2 = b.key === 'empty' ? '' : b.props.children;
                    return name1.localeCompare(name2);
                });
                return renderedItems;
            }

            const onSelectTraining = (event) => {
                var trainingTypeID = event?.target?.value || null;
                this.setState({ trainingTypeID });
                localStorage.setItem('trainingTypeID', trainingTypeID);
            }

            var measurementActive = this.data.inertia.status.logging === true || this.data.inertia.status.syncing === true || this.data.polar.status.logging === true || this.data.inertia.status.manualFileSync === true;

            return (
                <FormControl fullWidth sx={{ width: 250 }}>
                    <InputLabel id='training-label'>Training type</InputLabel>
                    <Select
                        labelId='training-label'
                        id='training-label'
                        disabled={measurementActive}
                        value={this.state.trainingTypeID || ''}
                        label='Training type'
                        onChange={onSelectTraining}
                    >
                        {getTrainingTypes()}
                    </Select>
                </FormControl>
            );
        }

        return (
            <Box className='sub-box'>
                <Box className='flex-row'>
                    <Box className='flex-col'>
                        {getSystemSelect()}
                        {getRpiInfo()}
                    </Box>
                    <Box className='flex-col'>
                        {getHorseSelect()}
                        {getTrainingSelect()}
                    </Box>
                    {getLoggingButtons()}
                    {renderComments()}
                </Box>
            </Box>
        );
    }

    renderMap() {
        if (!this.state.activeRoom)
            return;

        var points = [];
        var gnssData = this.data.inertia.sensors[this.data.inertia.gnssSensor]?.gnss;
        if (gnssData) {
            for (var gnssSample of gnssData) {
                if (gnssSample.valid) {
                    points.push([gnssSample.latitude, gnssSample.longitude]);
                }
            }
        }

        var zoom = 4;
        var position = [52, 7];
        var forceZoom = false;

        if (points.length > 0) {
            position = points[points.length - 1];
            if (this.firstCoord) {
                // zoom in when receiving first valid point
                this.firstCoord = false;
                forceZoom = true;
                zoom = 16;
            }
        }

        function ShowMarker() {
            const map = useMap();
            map.setView(position, forceZoom ? zoom : map.getZoom());
            return (
                <Marker position={position} />
            );
        }

        return (
            <Box className='sub-box'>
                <MapContainer center={position} zoom={zoom} className='map-container'>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                    />
                    <Polyline pathOptions={{ color: 'red' }} positions={points} />
                    {points.length > 0 ? <ShowMarker /> : undefined}
                </MapContainer>
            </Box>
        )
    }

    render() {
        if (this.state.navigate !== undefined) {
            return (
                <Navigate to={this.state.navigate} />
            )
        }

        if (!this.props.loggedIn)
            return;

        const toggleDrawer = (newOpen) => () => {
            this.setState({ drawerOpen: newOpen });
        };

        const onDrawerClick = (event) => {
            var dest = getNavigation(event.target.innerText, window.location.pathname);
            this.setState({
                drawerOpen: false,
                navigate: dest
            });
        };

        const handlePowerDownConfirmClose = () => {
            this.setState({ powerDownConfirm: false });
        };

        const onPowerDownConfirm = () => {
            var socket = getSocket();
            socket.emit('control', this.state.activeRoom, 'inertia', 'powerDown', true);
            handlePowerDownConfirmClose();
        };

        const handleStopConfirmClose = () => {
            this.setState({ stopConfirm: false });
        };

        const onStopConfirm = () => {
            var socket = getSocket();
            socket.emit('control', this.state.activeRoom, 'inertia', 'logging', { start: false, });

            if (!this.data.polar.status.connected || this.data.polar.status.scanning)
                socket.emit('control', this.state.activeRoom, 'polar', 'logging', { start: false, });

            handleStopConfirmClose();
        };

        const handleShutDownConfirmClose = () => {
            this.setState({ shutDownConfirm: false });
        };

        const onShutDownConfirm = () => {
            var socket = getSocket();
            socket.emit('control', this.state.activeRoom, 'system', 'shutDown');
            handleShutDownConfirmClose();
        };

        return (
            <Box sx={{ padding: '0.5rem' }}>
                <ThemeProvider theme={this.defaultTheme}>
                    <CssBaseline />
                    <Drawer open={this.state.drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 250 }} role='presentation'>
                            <List>
                                {getPages().map((text) => (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton onClick={onDrawerClick}>
                                            <ListItemText primary={text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>

                    <Dialog
                        open={this.state.powerDownConfirm === true}
                        onClose={handlePowerDownConfirmClose}
                    >
                        <DialogContent>
                            {'Are you sure you want to power down the gateway and all nodes?'}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePowerDownConfirmClose}>Cancel</Button>
                            <Button onClick={onPowerDownConfirm}>Confirm</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.stopConfirm === true}
                        onClose={handleStopConfirmClose}
                    >
                        <DialogContent>
                            {'Are you sure you want to ' + (this.data.inertia.status?.logging ? 'stop the measurement' : 'stop synchronizing') + '?\n'}
                            {'If a heart rate sensor is active, it will continue logging recovery heart rate data.'}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleStopConfirmClose}>Cancel</Button>
                            <Button onClick={onStopConfirm}>Confirm</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.shutDownConfirm === true}
                        onClose={handleShutDownConfirmClose}
                    >
                        <DialogContent>
                            {'Are you sure you want to SHUT DOWN the box?'}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleShutDownConfirmClose}>Cancel</Button>
                            <Button onClick={onShutDownConfirm}>Confirm</Button>
                        </DialogActions>
                    </Dialog>

                    <Box>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '20%' }} className='text-left'><Button onClick={toggleDrawer(true)} startIcon={<MenuIcon />}>Menu</Button></td>
                                    <td style={{ width: '60%' }} className='text-center'><Box sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.5rem' }}>Systems</Box></td>
                                    <td style={{ width: '20%' }} className='text-right'>{this.getServerStatus()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>

                    <Box>
                        {this.renderRPis()}

                        {this.renderInertia()}

                        <div className='my-cardGrid' style={{
                            gridTemplateColumns: 'repeat(auto-fit, minmax(min(400px, 95%), 1fr))',
                            gap: '0rem 1rem',
                            padding: 0,
                        }}>
                            {this.renderCharts()}

                            {this.renderMap()}
                        </div>
                    </Box>
                </ThemeProvider >
            </Box>
        )
    }
}

export default System;
