import io from 'socket.io-client';

var socket = undefined;
var isConnected = false;

export function getSocket() {
    createSocket();
    return socket;
}

export function socketConnected() {
    return isConnected;
}

export function disconnect() {
    if (socket) {
        socket.disconnect();
        socket = undefined;
        isConnected = false;
    }
}

export function getSymOrder() {
    return ['sternum', 'withers', 'head', 'sacrum'];
}

function createSocket() {
    if (socket)
        return;

    const user = JSON.parse(localStorage.getItem('user'));
    var token = user?.token;

    var apiServer = process.env.REACT_APP_API_SERVER;
    socket = io(apiServer, {
        path: '/rpi',
        auth: { token },
        extraHeaders: {
            authorization: 'Bearer ' + token,
        },
    });

    socket.on('connect', () => {
        console.log('socket connected');
        isConnected = true;
    });

    socket.on('disconnect', (reason) => {
        isConnected = false;
        console.log('socket disconnected: ' + reason);
        if (socket && reason === 'io server disconnect')
            socket.connect();
    });

    socket.on('connect_error', (err) => {
        isConnected = false;
        console.log('socket connect_error: ' + err?.description);
    });

    // socket.onAny((type, channel, subChannel, data) => {
    //     console.log('socket ' + type + ' ' + channel + ' - ' + subChannel + ': ' + JSON.stringify(data, null, 2));
    // });

    socket.io.on('reconnect_attempt', () => {
        console.log('socket reconnecting');
    });

    socket.io.on('reconnect', () => {
        console.log('socket reconnected');
    });
}
