const storeName = 'measurementFiles';
const storeKey = 'measurementID';
const dbVersion = 1;
var db = undefined;


async function initDB() {
    if (db)
        return;

    var dbName = 'varenne-db';
    var stores = [{ name: storeName, keyPath: storeKey }];

    db = await new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, dbVersion);
        request.onsuccess = (event) => {
            resolve(event.target.result);
        };
        request.onerror = (event) => {
            reject(event.target.error);
        };
        request.onupgradeneeded = (event) => {
            stores.forEach((store) => {
                const objectStore = event.target.result.createObjectStore(store.name, {
                    keyPath: store.keyPath,
                });
                objectStore.createIndex(store.keyPath, store.keyPath, { unique: true });
            });
        };
    });
};


export async function getFile(measurementID, systemID) {
    var result = null;
    try {
        await initDB();

        var id = measurementID + '|' + systemID;

        var entry = await new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readonly');
            const objectStore = transaction.objectStore(storeName);
            const objectStoreRequest = objectStore.get(id);
            objectStoreRequest.onsuccess = (event) => {
                resolve(event.target.result);
            };
            objectStoreRequest.onerror = (event) => {
                reject(event.target.error);
            };
        });
        result = entry?.data;
    } catch (error) {
        console.error('getFile ' + error);
    }

    return result;
};


export async function putFile(measurementID, systemID, data, info) {
    try {
        await initDB();

        var id = measurementID + '|' + systemID;

        await new Promise((resolve, reject) => {
            const newItem = {
                [storeKey]: id,
                data: data,
                info: info,
            };

            const transaction = db.transaction(storeName, 'readwrite');
            const objectStore = transaction.objectStore(storeName);
            const objectStoreRequest = objectStore.put(newItem);
            objectStoreRequest.onsuccess = (event) => {
                resolve(event.target.result);
            };
            objectStoreRequest.onerror = (event) => {
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error('putFile ' + error);
    }
};


export async function removeFile(measurementID, systemID) {
    try {
        await initDB();

        var id = measurementID + '|' + systemID;

        await new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readwrite');
            const objectStore = transaction.objectStore(storeName);
            const objectStoreRequest = objectStore.delete(id);
            objectStoreRequest.onsuccess = (event) => {
                resolve(event.target.result);
            };
            objectStoreRequest.onerror = (event) => {
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error('removeFile ' + error);
    }
};


export async function clearFiles() {
    try {
        await initDB();

        await new Promise((resolve, reject) => {
            const transaction = db.transaction(storeName, 'readwrite');
            const objectStore = transaction.objectStore(storeName);
            const objectStoreRequest = objectStore.clear();
            objectStoreRequest.onsuccess = (event) => {
                resolve(event.target.result);
            };
            objectStoreRequest.onerror = (event) => {
                reject(event.target.error);
            };
        });
    } catch (error) {
        console.error('clearFiles ' + error);
    }
};
