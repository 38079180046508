import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { getSocket, socketConnected } from './Socket';
import { createChartCompareObject, formatTime } from './Chart';
import { getPages, getNavigation } from './App';
import { Box, Button, CssBaseline, Dialog, DialogActions, DialogContent, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Edit as EditIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { Scatter } from 'react-chartjs-2'


class Compare extends Component {
    constructor(props) {
        super(props);

        this.defaultTheme = createTheme({ palette: { mode: 'dark' } });

        // allow to use 'this' to access class members
        this.connectHandler = this.connectHandler.bind(this);
        this.measurementHandler = this.measurementHandler.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.horseHandler = this.horseHandler.bind(this);

        this.chart = createChartCompareObject();

        this.state = {
            isConnected: socketConnected(),
            navigate: undefined,
            drawerOpen: false,
            clearConfirm: false,
            measurements: [],
            users: [],
            horses: [],
            notesEditDetails: undefined,
        }
    }

    componentDidMount() {
        var socket = getSocket();
        if (socket) {
            socket.on('connect', this.connectHandler);
            socket.on('disconnect', this.connectHandler);
            socket.on('measurements', this.measurementHandler);
            socket.on('users', this.userHandler);
            socket.on('horses', this.horseHandler);
        }

        this.getUsers();
        this.getHorses();
        this.getMeasurements();
    }

    componentWillUnmount() {
        var socket = getSocket();
        if (socket) {
            socket.off('connect', this.connectHandler);
            socket.off('disconnect', this.connectHandler);
            socket.off('measurements', this.measurementHandler);
            socket.off('users', this.userHandler);
            socket.off('horses', this.horseHandler);
        }

        const chart = this.chart.reference.current;
        if (chart)
            chart.destroy();
    }

    getMeasurements() {
        var socket = getSocket();
        socket.emit('measurements', 'request', 'get');
    }

    getUsers() {
        var socket = getSocket();
        socket.emit('users', 'request', 'get');
    }

    getHorses() {
        var socket = getSocket();
        socket.emit('horses', 'request', 'get');
    }


    connectHandler() {
        this.setState({ isConnected: socketConnected() });
    }

    measurementHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ measurements: data });
            }
            else {
                this.getMeasurements();
            }
        }
    }

    userHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ users: data });
            }
        }
    }

    horseHandler(channel, subChannel, data) {
        if (channel === 'response') {
            if (subChannel === 'get') {
                this.setState({ horses: data });
            }
            else {
                this.getHorses();
            }
        }
    }

    getCompareItems() {
        var compareItems = [];
        try {
            compareItems = JSON.parse(localStorage.getItem('compareItems'));
        } catch (e) {
            console.log(e);
        }
        if (!Array.isArray(compareItems))
            compareItems = [];

        compareItems.sort((a, b) => {
            if (!this.state.measurements)
                return 0;

            const measurement1 = this.state.measurements.find((meas) => meas.measurementID === a.measurementID);
            var date1 = new Date(measurement1?.date);
            date1.setSeconds(date1.getSeconds() + a.details.range[0]);

            const measurement2 = this.state.measurements.find((meas) => meas.measurementID === b.measurementID);
            var date2 = new Date(measurement2?.date);
            date2.setSeconds(date2.getSeconds() + b.details.range[0]);

            return (date1 > date2) - (date1 < date2);
        });

        return compareItems;
    }

    renderCompare() {
        var rows = [];
        var compareItems = this.getCompareItems();
        for (const compareItem of compareItems) {
            const measurement = this.state.measurements.find((meas) => meas.measurementID === compareItem.measurementID);
            if (!measurement)
                continue;
            const system = measurement.systems.find((sys) => sys.userID === compareItem.systemID);
            if (!system)
                continue;
            const segment = system.segments.find((seg) => seg.date === compareItem.segmentID);
            if (!segment)
                continue;

            rows.push({
                id: compareItem.measurementID + '-' + compareItem.systemID + '-' + compareItem.segmentID,
                compareItem,
            });
        }

        const columns = [
            {
                field: 'date',
                headerName: 'Date',
                type: 'date',
                sortable: true,
                width: 200,
                valueGetter: (value, row) => {
                    // used for sorting, date + segment start
                    const measurement = this.state.measurements.find((meas) => meas.measurementID === row.compareItem.measurementID);
                    var date = new Date(measurement?.date);
                    date.setSeconds(date.getSeconds() + row.compareItem.details.range[0]);
                    return date;
                },
                renderCell: (params) => {
                    var compareItem = params.row.compareItem;
                    var date = '\u00a0';
                    const measurement = this.state.measurements.find((meas) => meas.measurementID === compareItem.measurementID);
                    if (measurement) {
                        date = new Date(measurement.date).toLocaleString();
                    }
                    return <Box className='DataGrid-cell'>{date}</Box>;
                },
            },
            {
                field: 'range',
                headerName: 'Segment',
                sortable: false,
                sortComparator: (value1, value2) => value1[0] - value2[0],
                width: 150,
                renderCell: (params) => {
                    var details = params.row.compareItem.details;
                    var range = formatTime(details.range[0]) + ' - ' + formatTime(details.range[1]);
                    return <Box className='DataGrid-cell'>{range}</Box>;
                },
            },
            {
                field: 'horse',
                headerName: 'Horse',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var compareItem = params.row.compareItem;
                    var horseName = '\u00a0';
                    var username = 'unknown';
                    const measurement = this.state.measurements.find((meas) => meas.measurementID === compareItem.measurementID);
                    if (measurement) {
                        const system = measurement.systems.find((sys) => sys.userID === compareItem.systemID);
                        if (system) {
                            horseName = this.state.horses.find((item) => item.horseID === system.horseID)?.name || '\u00a0';
                            username = this.state.users.find((item) => item.userID === system.userID)?.username || 'unknown';
                        }
                    }
                    return (
                        <Box className='DataGrid-cell'>
                            <Box color='text.primary' sx={{ fontSize: '1rem' }} key={0}>{horseName}</Box>
                            <Box color='text.secondary' sx={{ fontSize: '0.75rem' }} key={1}>{username}</Box>
                        </Box>
                    );
                },
            },
            {
                field: 'duration',
                headerName: 'Duration',
                sortable: false,
                width: 100,
                renderCell: (params) => {
                    var details = params.row.compareItem.details;
                    var duration = formatTime(details.range[1] - details.range[0]);
                    return <Box className='DataGrid-cell'>{duration}</Box>;
                },
            },
            {
                field: 'heartRate',
                headerName: 'Heart Rate (bpm)',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var heartRate = params.row.compareItem.details.heartRate;
                    var mean = heartRate.mean !== null ? heartRate.mean.toFixed(2) : '';
                    var stddev = heartRate.stddev !== null ? ('\u00B1' + heartRate.stddev.toFixed(2)) : '';
                    var max = heartRate.max !== null ? ('max: ' + heartRate.max.toFixed(0)) : '';

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                <span>{mean}</span>
                                <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{stddev}</span>
                            </Box>
                            <Box sx={{ marginTop: '0.5rem' }} >{max}</Box>
                        </Box>
                    );
                },
            },
            {
                field: 'symmetry',
                headerName: 'Symmetry',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var symmetry = params.row.compareItem.details.symmetry;
                    var mean = symmetry.mean !== null ? symmetry.mean.toFixed(2) : '';
                    var stddev = symmetry.stddev !== null ? ('\u00B1' + symmetry.stddev.toFixed(2)) : '';
                    var max = '';// symmetry.max !== null ? ('max: ' + symmetry.max.toFixed(0)) : '';

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                <span>{mean}</span>
                                <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{stddev}</span>
                            </Box>
                            <Box sx={{ marginTop: '0.5rem' }} >{max}</Box>
                        </Box>
                    );
                },
            },
            {
                field: 'pace',
                headerName: 'Pace (min/km)',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var speedToPace = (speed) => {
                        return 60 / speed;
                    }
                    var formatPace = (value) => {
                        var minute = Math.floor(value);
                        var second = Math.round((value - minute) * 60);
                        if (second >= 60) {
                            minute++;
                            second -= 60;
                        }
                        return minute + ':' + second.toFixed(0).padStart(2, '0');
                    }

                    var speed = params.row.compareItem.details.speed;
                    var mean = speed.mean !== null ? formatPace(speedToPace(speed.mean)) : '';
                    var stddev = speed.stddev !== null ? ('\u00B1' + formatPace(speed.stddev / speed.mean * speedToPace(speed.mean))) : '';
                    var max = speed.max !== null ? ('max: ' + formatPace(speedToPace(speed.max))) : '';

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                <span>{mean}</span>
                                <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{stddev}</span>
                            </Box>
                            <Box sx={{ marginTop: '0.5rem' }} >{max}</Box>
                        </Box>
                    );
                }
            },
            {
                field: 'speed',
                headerName: 'Speed (km/h)',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var speed = params.row.compareItem.details.speed;
                    var mean = speed.mean !== null ? speed.mean.toFixed(2) : '';
                    var stddev = speed.stddev !== null ? ('\u00B1' + speed.stddev.toFixed(2)) : '';
                    var max = speed.max !== null ? ('max: ' + speed.max.toFixed(2)) : '';

                    return (
                        <Box className='DataGrid-cell' sx={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}>
                            <Box>
                                <span>{mean}</span>
                                <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{stddev}</span>
                            </Box>
                            <Box sx={{ marginTop: '0.5rem' }} >{max}</Box>
                        </Box>
                    );
                }
            },
            {
                field: 'quality',
                headerName: 'GNSS Quality',
                sortable: false,
                width: 150,
                renderCell: (params) => {
                    var quality = ''
                    var satellites = params.row.compareItem.details.satellites.mean;
                    if (satellites >= 8)
                        quality = 'good';
                    else if (satellites >= 4)
                        quality = 'mediocre';
                    else if (satellites !== null)
                        quality = 'bad';

                    var satCount = '';
                    if (satellites !== null)
                        satCount = ' (' + Math.round(satellites) + ')';

                    return (
                        <Box className='DataGrid-cell'>
                            <span>{quality}</span>
                            <span style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}>{satCount}</span>
                        </Box>
                    );
                }
            },
            {
                field: 'notes',
                headerName: 'Notes',
                sortable: false,
                editable: true,
                width: 250,
                renderCell: (params) => {
                    var compareItem = params.row.compareItem;

                    const onEditSegmentNotes = () => {
                        this.setState({
                            notesEditDetails: {
                                measurementID: compareItem.measurementID,
                                systemID: compareItem.systemID,
                                segmentID: compareItem.segmentID,
                            }
                        });
                    }

                    var notes = '';
                    const measurement = this.state.measurements.find((meas) => meas.measurementID === compareItem.measurementID);
                    if (measurement) {
                        const system = measurement.systems.find((sys) => sys.userID === compareItem.systemID);
                        if (system) {
                            const segment = system.segments.find((seg) => seg.date === compareItem.segmentID);
                            if (segment?.details?.notes) {
                                notes = segment.details.notes;
                            }
                        }
                    }

                    return (
                        <Box className='DataGrid-cell' sx={{ whiteSpace: 'pre-line' }}>
                            {notes}
                            <IconButton
                                sx={{ alignSelf: 'end', }}
                                onClick={onEditSegmentNotes}
                                color={'primary'}
                            >
                                <EditIcon />
                            </IconButton>
                        </Box>
                    );
                },
            },
            {
                field: 'actions',
                headerName: 'Actions',
                sortable: false,
                width: 200,
                renderCell: (params) => {
                    const onRemoveSegment = (event) => {
                        event.stopPropagation(); // don't select row when clicking remove button

                        var compareItems = this.getCompareItems();
                        var compareItem = params.row.compareItem;
                        compareItems = compareItems.filter((item) => !(item.measurementID === compareItem.measurementID && item.systemID === compareItem.systemID && item.segmentID === compareItem.segmentID));
                        localStorage.setItem('compareItems', JSON.stringify(compareItems));
                        this.setState({});
                    };

                    const onSelectMeasurement = () => {
                        var compareItem = params.row.compareItem;
                        this.props.setMeasurement({ measurementID: compareItem.measurementID, systemID: compareItem.systemID });
                        this.setState({ navigate: '/details' });
                    };

                    return (
                        <Box className='DataGrid-cell'>
                            <Stack
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                    alignItems: 'center'
                                }}
                            >
                                <Button variant='contained' focusRipple onClick={onSelectMeasurement}>Details</Button>
                                <Button variant='outlined' focusRipple onClick={onRemoveSegment}>Remove</Button>
                            </Stack>
                        </Box>
                    );
                }
            },
        ];

        const setSortModel = (data) => {
            if (data && data.length > 0) {
                localStorage.setItem('compareSortField', data[0].field);
                localStorage.setItem('compareSortDirection', data[0].sort);
            }
        };

        var getClearButton = () => {
            var onClearCompare = () => {
                this.setState({ clearConfirm: true });
            };

            return (
                <Button
                    variant='outlined'
                    sx={{
                        display: 'block',
                        margin: '0.5rem 2rem 0.5rem auto',
                    }}
                    onClick={onClearCompare}
                >Clear Comparison</Button>
            );
        };

        const handleClearConfirmClose = () => {
            this.setState({ clearConfirm: false });
        };

        const onClearConfirm = () => {
            localStorage.setItem('compareItems', JSON.stringify([]));
            handleClearConfirmClose();
        };

        const onSaveNotes = (event) => {
            event.preventDefault();
            var notes = '';
            const formData = new FormData(event?.currentTarget);
            if (formData)
                notes = Object.fromEntries(formData.entries()).notes;

            var socket = getSocket();
            socket.emit('measurements', 'request', 'addSegment', {
                measurementID: this.state.notesEditDetails.measurementID,
                systemID: this.state.notesEditDetails.systemID,
                segment: {
                    date: this.state.notesEditDetails.segmentID,
                    notes: notes,
                },
            });

            handleNotesClose();
        };

        const handleNotesClose = () => {
            this.setState({ notesEditDetails: undefined });
        };

        var noteText = '';
        if (this.state.notesEditDetails !== undefined) {
            const measurement = this.state.measurements.find((meas) => meas.measurementID === this.state.notesEditDetails.measurementID);
            if (measurement) {
                const system = measurement.systems.find((sys) => sys.userID === this.state.notesEditDetails.systemID);
                if (system) {
                    const segment = system.segments.find((seg) => seg.date === this.state.notesEditDetails.segmentID);
                    if (segment?.details?.notes) {
                        noteText = segment.details.notes;
                    }
                }
            }
        }

        return (
            <Box sx={{ width: '100%' }}>

                <Dialog
                    open={this.state.clearConfirm === true}
                    onClose={handleClearConfirmClose}
                >
                    <DialogContent>
                        {'Are you sure you want to clear all comparison segments?\n'}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClearConfirmClose}>Cancel</Button>
                        <Button onClick={onClearConfirm}>Confirm</Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.notesEditDetails !== undefined}
                    onClose={handleNotesClose}
                    fullWidth={true}
                    maxWidth={'sm'}
                    PaperProps={{
                        component: 'form',
                        onSubmit: onSaveNotes,
                    }}
                >
                    <DialogContent>
                        <TextField
                            autoFocus
                            fullWidth
                            margin='normal'
                            multiline
                            rows={3}
                            label='Notes'
                            name='notes'
                            defaultValue={noteText}
                            onFocus={event => event.target.selectionStart = event.target.selectionEnd = event.target.value.length}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNotesClose}>Cancel</Button>
                        <Button type='submit'>Save</Button>
                    </DialogActions>
                </Dialog>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    hideFooterSelectedRowCount
                    sortingOrder={['desc', 'asc']}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                page: 0,
                                pageSize: 100,
                            },
                        },
                        sorting: {
                            sortModel: [{
                                field: localStorage.getItem('compareSortField') || 'date',
                                sort: localStorage.getItem('compareSortDirection') || 'desc',
                            }],
                        },
                    }}
                    onSortModelChange={setSortModel}
                    pageSizeOptions={[100]}
                    slots={{
                        footer: getClearButton,
                    }}
                />
            </Box>
        );
    }

    createCompareCharts() {
        if (!this.chart /* || this.chartInfo.created */)
            return;

        const chart = this.chart?.reference?.current;
        var ds = chart?.data?.datasets;
        if (!chart || !ds)
            return;

        this.chart.created = true;

        var hrPoints = [];
        var symmetryPoints = [];
        var speedPoints = [];

        var addPoints = function (x, y, date, range) {
            hrPoints.push({ x, y: y[0], date, range });
            symmetryPoints.push({ x, y: y[1], date, range });
            speedPoints.push({ x, y: y[2], date, range });
        }

        var index = 0;
        addPoints(index++, [NaN, NaN, NaN], undefined, undefined);

        var compareItems = this.getCompareItems();
        for (const compareItem of compareItems) {
            const measurement = this.state.measurements.find((meas) => meas.measurementID === compareItem.measurementID);
            if (!measurement)
                continue;
            const system = measurement.systems.find((sys) => sys.userID === compareItem.systemID);
            if (!system)
                continue;
            const segment = system.segments.find((seg) => seg.date === compareItem.segmentID);
            if (!segment)
                continue;

            addPoints(index++, [compareItem.details.heartRate.mean, compareItem.details.symmetry.mean, compareItem.details.speed.mean], measurement.date, segment.details.range);
        }

        addPoints(index++, [NaN, NaN, NaN], undefined, undefined);

        for (var datasetIndex = 0; datasetIndex < ds.length; ++datasetIndex) {
            var dataset = ds[datasetIndex];
            switch (datasetIndex) {
                case 0: {
                    dataset.data = symmetryPoints;
                    break;
                }
                case 1: {
                    dataset.data = hrPoints;
                    break;
                }
                case 2: {
                    dataset.data = speedPoints;
                    break;
                }
                default:
                    break;
            }
        }

        chart.update('none');
    }

    renderCharts() {
        this.createCompareCharts();

        return (
            <Box className='sub-box'>
                <Box className='chart-container'>
                    <Scatter ref={this.chart.reference} options={this.chart.options} data={this.chart.data} />
                </Box>
            </Box>
        );
    }

    getConnectedStyle(available) {
        return {
            color: available ? undefined : 'red',
            fontWeight: available ? undefined : 'bold',
        };
    }

    getServerStatus() {
        var connected = this.state.isConnected === true;
        if (connected)
            return;

        return (
            <Box>
                {'Server: '}
                <span style={this.getConnectedStyle(connected)}>
                    {(connected ? 'online' : 'offline')}
                </span>
            </Box>
        )
    }

    render() {
        if (this.state.navigate !== undefined) {
            return (
                <Navigate to={this.state.navigate} />
            )
        }

        if (!this.props.loggedIn)
            return;

        const toggleDrawer = (newOpen) => () => {
            this.setState({ drawerOpen: newOpen });
        };

        const onDrawerClick = (event) => {
            var dest = getNavigation(event.target.innerText, window.location.pathname);
            this.setState({
                drawerOpen: false,
                navigate: dest
            });
        };

        return (
            <Box sx={{ padding: '0.5rem' }}>
                <ThemeProvider theme={this.defaultTheme}>
                    <CssBaseline />
                    <Drawer open={this.state.drawerOpen} onClose={toggleDrawer(false)}>
                        <Box sx={{ width: 250 }} role='presentation'>
                            <List>
                                {getPages().map((text) => (
                                    <ListItem key={text} disablePadding>
                                        <ListItemButton onClick={onDrawerClick}>
                                            <ListItemText primary={text} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Drawer>

                    <Box>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '20%' }} className='text-left'><Button onClick={toggleDrawer(true)} startIcon={<MenuIcon />}>Menu</Button></td>
                                    <td style={{ width: '60%' }} className='text-center'><Box sx={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.5rem' }}>Compare</Box></td>
                                    <td style={{ width: '20%' }} className='text-right'>{this.getServerStatus()}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Box>
                    {this.renderCompare()}

                    {this.renderCharts()}

                </ThemeProvider >
            </Box>
        )
    }
}

export default Compare;
